// ChartResolutionSelector.tsx

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { eventCloud } from "./EventCloud";
import { Asset } from "./interfaces/asset.interface";

type Resolution = "1S" | "1" | "5" | "15" | "60" | "D";

const ChartResolutionSelector: React.FC = () => {
  const [selectedResolution, setSelectedResolution] = useState<Resolution>(
    localStorage.getItem("dmex.selectedChartResolution") as Resolution || "1"
  );
  const [assetLastPrice, setAssetLastPrice] = useState("");
  const [isPriceUp, setIsPriceUp] = useState(true);
  const [priceChange, setPriceChange] = useState(0);
  const [volume, setVolume] = useState(0);


  const resolutions: Resolution[] = ["1S", "1", "5", "15", "60", "D"];
  const resolutionsName = ["1S", "1M", "5M", "15M", "1H", "1D"];

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(
    eventCloud.selectedAsset.getValue()
  );


  useEffect(() => {
    localStorage.setItem(
      "dmex.selectedChartResolution",
      selectedResolution
    );
  }, [selectedResolution]);

  useEffect(() => {
    if (eventCloud.selectedAsset) {
      const assetSubscription = eventCloud.selectedAsset.subscribe((asset) => {
        if (asset) {
          setSelectedAsset(asset);
          setAssetLastPrice(Number(asset.last_price).toString());
          setPriceChange(asset.change);
          setVolume(asset.volume);
        }
      });

      return () => {
        assetSubscription.unsubscribe();
      };
    }
  }, []);

  // useEffect(() => {
  //   const handleNewTrade = (trade: any) => {
  //     if (!selectedAsset) return;
  //     if (trade.Asset == selectedAsset.symbol) {
  //       //console.log("[OrderBox: handleNewTrade] ", trade);
  //       setAssetLastPrice(Number(trade.Price).toString());
  //       setIsPriceUp(trade.PriceUp);

  //       var change =
  //         ((trade.Price / 1e8 - selectedAsset.open) / selectedAsset.open) * 100;

  //       setPriceChange(change);
  //     }
  //   };

  //   const unsubscribeNewTrade = eventCloud.on("newTrade", handleNewTrade);

  //   return () => {
  //     unsubscribeNewTrade();
  //   };
  // }, [selectedAsset]);

  const handleResolutionChange = (resolution: Resolution) => {
    setSelectedResolution(resolution);
    eventCloud.setSelectedResolution(resolution);
    // You can also pass this selection to a parent component or perform other actions here
  };




  if (!selectedAsset) return null;

  return (
    <div className="bg-black bg-opacity-30 pt-0.5 select-none">
      <div className="flex flex-wrap px-1 sm:px-4 py-1 border-b border-gray-650 border-r">
        <div className="mr-4 text-xs">
          {selectedAsset?.symbol.toUpperCase()}
        </div>
        <span className="text-xs">
          {resolutions.map((resolution, i) => (
            <label
              key={resolution}
              className={classNames("cursor-pointer px-2 pb-1 text-xs", {
                "border-b-2 border-white": selectedResolution === resolution,
                "text-gray-450": selectedResolution !== resolution,
                "text-white": selectedResolution === resolution,
              })}
            >
              <input
                type="radio"
                value={resolution}
                checked={selectedResolution === resolution}
                onChange={() => handleResolutionChange(resolution)}
                className="invisible absolute"
              />
              {resolutionsName[i]}
            </label>
          ))}
        </span>
        
        {/*<span className="text-xs ml-2">
          O:{" "}
          {formatPrice(
            selectedAsset.open * 1e8,
            Number(selectedAsset?.decimals)
          )}{" "}
          H:{" "}
          {formatPrice(
            selectedAsset.high * 1e8,
            Number(selectedAsset?.decimals)
          )}{" "}
          L:{" "}
          {formatPrice(
            selectedAsset.low * 1e8,
            Number(selectedAsset?.decimals)
          )}{" "}
          C:{" "}
          <span
            className={classNames([
              isPriceUp
                ? "text-green-550"
                : "text-red-550",
            ])}
          >
          {formatPrice(
            Number(assetLastPrice),
            Number(selectedAsset?.decimals)
          )}
          </span>{" "}
          %:{" "}
          <span
            className={classNames([
              priceChange >= 0
                ? "text-green-550"
                : "text-red-550",
            ])}
          >
            {priceChange >= 0 ? "+" : "-"}
            {Math.abs(priceChange).toFixed(2)}%
          </span>           
        </span>*/}
      </div>
    </div>
  );
};

const formatPrice = (price: number, decimals: number): string => {
  return (price / 1e8).toFixed(decimals);
};

const formatVolume = (volume: number): string => {
  return Intl.NumberFormat("en-US").format(+volume.toFixed(0));
};

export default ChartResolutionSelector;
